<script>


import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import ProgressDialog from './components/progress_dialog'

import StatementMailPreviewDialog from './components/statement_mail_preview.vue'
import StatementMailForwardDialog from './components/statement_mail_forward.vue'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'


import { getPMApi } from '@/api/pm'
import { financial, autoComplete } from '@/api/misc'
import moment from 'moment'
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

/**
 * User list component
 */
export default {
  page: {
    title: "RTA Payment",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      searched_data: [],

      pmList: [],


      title: "PM",
      items: [
        {
          text: "PM",
          href: "/",
        },
        {
          text: "RTA Payment",
          active: true,
        },
      ],

      search_str: '',
      queryed_type: '',
      queryed_id: '',

      filter: '',
      sortDesc: '',
      sortBy: '',

      totalRows: 0,
      currentPage: 1,
      perPage: 500,
      fields: [
        { key: "C", label: '' },
        {
          key: "PMID",
          sortable: true,
          tdClass: 'align-middle'
        },



        { key: 'Deposit', tdClass: 'align-middle' },
        { key: "Balance", sortable: true },
        { key: "Address", tdClass: 'align-middle' },
        { key: 'Email1', tdClass: 'align-middle' },
        { key: 'Email2', tdClass: 'align-middle' },
        { key: 'Email3', tdClass: 'align-middle' },
        { key: 'Action', tdClass: 'align-middle' },

      ],

      statement_job_id: '',
      task_id: '',
      current_progress: 0,
      statement_range: '',
      preview_item: {},
      description_flag: 1,

      unclosed_pm_list: [],
    };
  },
  components: {
    Layout,
    PageHeader,
    ProgressDialog,
    VueBootstrapTypeahead,
    flatPickr,
    StatementMailPreviewDialog,
    StatementMailForwardDialog
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },
    selected_rows() {
      return this.pmList.filter(e => e.checked == true).length
    },
  },

  created() {

  },
  mounted() {
    let today = moment()
    let start_date = today.format("YYYY-MM-01")
    let end_date = today.format("YYYY-MM-") + today.endOf('month')

    this.statement_range = start_date + ' to ' + end_date

  },
  unmounted() {
    clearInterval(this.task_id)
  },

  methods: {

    f(n, d = 2) {
      return financial(n, d)
    },

    queryList() {
      this.$bvModal.show('ProgressDiloag')
      let data = {
        queryed_type: this.queryed_type,
        queryed_id: this.queryed_id
      }

      this.pmList = []

      getPMApi().query_statement_email_list(data).then((res) => {
        this.pmList = []
        this.$bvModal.hide('ProgressDiloag')
        if (res.errCode != 0) {
          this.$alertify.error("Query Payment PM  Failed " + res.errCode);
          return
        }
        res.data.map((o) => {

          o.owners.map(e => {
            e.agent_id = o.agent_id
            e.property_id = o.property_id,
              e.pm_id = o.id,
              e.balance_in_trust = financial(Number(o.balance_in_trust), 2),
              e.balance_available = financial(Number(o.balance_available), 2),
              e.deposit = financial(Number(o.deposit), 2),
              e.checked = false
            this.pmList.push(e)
          })


        })

        this.totalRows = res.data.length
        this.filtered_pm_list = this.pmList

        this.$bvModal.hide('ProgressDiloag')
      })
    },

    submit_request() {

      this.$alertify.confirmWithTitle(
        "Confirmation",
        "Are you sure to send statement?",
        () => {

          let pm_list = []
          this.filtered_pm_list.map(e => {
            if (e.checked == true) {
              pm_list.push(e)
            }
          })

          let ar_list = this.statement_range.split(" to ")
          let start_date = ar_list[0]
          let end_date = ar_list[1]
          let data = {
            start_date: start_date,
            end_date: end_date,
            pm_list: pm_list
          }


          this.$bvModal.show('CloseProgressDiloag')

          getPMApi().send_statement(data).then(res => {
            if (res.errCode == 0) {
              clearInterval(this.task_id)
              this.statement_job_id = res.data.job.job_id
              this.task_id = setInterval(this.queryProgress, 2000)

            } else {
              clearInterval(this.task_id)
              this.$bvModal.hide('CloseProgressDiloag')
              this.$alertify.error("Send Statement Failed " + res.errCode);
            }
          })

        },
      )

    },



    queryProgress() {

      getPMApi().query_export_job_status({ job_id: this.statement_job_id }).then(res => {
        if (res.errCode == 0) {
          if (res.data.error) {
            this.current_progress = 100
            clearInterval(this.task_id)
            this.$alertify.message("PM Close Failed, please re-do close ");
            this.$bvModal.hide('CloseProgressDiloag')
          } else {
            if (res.data.percentage == 100 || res.data.state == 'FINISHED') {
              this.$bvModal.hide('CloseProgressDiloag')
              clearInterval(this.task_id)
              this.$alertify.message("Statement Request successfully");
              //this.$router.push({name : 'ap-pm-payment-list'})
              this.filtered_pm_list = []
              this.pmList = []
            } else {
              this.current_progress = res.data.percentage
            }
          }
        } else {
          clearInterval(this.task_id)
        }
      })
    },



    onSelectAllPM(evt) {
      this.filtered_pm_list.map(e => {
        e.checked = evt.target.checked

      })
    },

    onPMSelected(evt, pm) {
      pm.checked = evt.target.checked
      if (evt.target.checked == false) {
        let p = this.unclosed_pm_list.find(e => e.property_id == pm.property_id)
        if (!p) {
          this.unclosed_pm_list.push({ property_id: pm.property_id })
        }
      } else {
        this.unclosed_pm_list = this.unclosed_pm_list.filter(e => e.property_id != pm.property_id)
      }
    },





    onSearch(txt) {
      this.searched_data = []
      autoComplete({ types: ['PM', 'AGENT'], str: txt }).then(res => {
        if (res.errCode == 0) {
          res.data.map(e => {
            this.searched_data.push({
              str_id: e.str_id,
              s_type: e.s_type,
              obj: e
            })
          })

        }
      })
    },

    onSearchedSelected(e) {

      this.queryed_type = e.obj.s_type
      this.queryed_id = e.obj.s_type == 'PM' ? e.obj.property_id : e.obj.id
      this.queryList()
    },

    showPreview(item) {

      let ar_list = this.statement_range.split(" to ")


      item.start_date = ar_list[0]
      item.end_date = ar_list[1]
      this.preview_item = item

      this.$bvModal.show('modal-mail-prevew')
    },


    showForward() {
      this.unclosed_pm_list = this.pmList.filter(e => e.checked == true)
      console.log(this.unclosed_pm_list.length)
      let ar_list = this.statement_range.split(" to ")

      let item = {}
      item.start_date = ar_list[0]
      item.end_date = ar_list[1]
      item.description_flag = this.description_flag
      this.preview_item = item

      this.$bvModal.show('modal-mail-forward')
    },



  },

  watch: {

    sortBy() {

      this.list_sort()
    },

    sortDesc() {
      this.list_sort()
    },

    search_str(search_str) {
      this.onSearch(search_str)
    },


  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-modal centerd id="ProgressDiloag" title="Waiting...." size="lg" hide-footer hide-header-close
      no-close-on-backdrop no-close-on-esc>
      <ProgressDialog />
    </b-modal>

    <b-modal centerd id="CloseProgressDiloag" title="Waiting...." size="lg" hide-footer hide-header-close
      no-close-on-backdrop no-close-on-esc>
      <b-progress height="20px" :value="current_progress"></b-progress>
    </b-modal>

    <b-modal centered id="modal-mail-prevew" title="Preview" size="lg" hide-footer hide-header-close
      no-close-on-backdrop no-close-on-esc>
      <StatementMailPreviewDialog :mail_item="preview_item" @close="$bvModal.hide('modal-mail-prevew')" />
    </b-modal>

    <b-modal centered id="modal-mail-forward" title="Forward" size="lg" hide-footer hide-header-close
      no-close-on-backdrop no-close-on-esc>
      <StatementMailForwardDialog :mail_item="preview_item" :rta_list="unclosed_pm_list"
        @close="$bvModal.hide('modal-mail-forward')" />
    </b-modal>





    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">

            <div class="row">
              <label class="form-label">Search RTAs</label>
            </div>
            <hr class="mt-1">
            <div class="row">
              <div class="col-md-3">
                <div class="mb-3">
                  <vue-bootstrap-typeahead v-model="search_str" :data="searched_data"
                    :serializer="s => s.s_type == 'PM' ? 'PM:' + s.str_id : 'Agent:' + s.str_id"
                    placeholder="Search by Agent or PM Code" :foramterDisplay="s => s.str_id"
                    @hit="onSearchedSelected($event)" autocomplete="off" />
                </div>
              </div>
            </div>


            <!-- end row -->
            <div class="row  mt-3">
              <label class="form-label">RTA List</label>
            </div>
            <hr class="mt-1">


            
            <div class="row">

              <div class="col-md-3">
                <div class="mb-3 form-floating">
                  <flat-pickr v-model="statement_range" :config="{ mode: 'range' }" placeholder="Select a date"
                    class="form-control"></flat-pickr>
                    <label class="form-control-label" for="statement_range">Statement Range</label>
                </div>
              </div>


              <div class="col-md-2">
                <div class="mb-3 form-floating">
                  <b-select v-model="description_flag" class="form-select" id="description_flag"
                    placeholder="No Description"
                    :options="[{ text: 'No Description', value: '0' }, { text: 'With Description', value: '1' }]"></b-select>
                  <label class="form-control-label" for="description_flag">Description Flag</label>
                </div>
              </div>

            </div>

            <div class="row">

              <div class="col-md-5">
                <div class="d-flex flex-wrap  gap-2 mb-3">
                  <b-button variant="primary" @click="submit_request">Send Statement</b-button>
                  <b-button variant="outline-primary" @click="showForward"><i class="uil uil-user me-2"></i> Forward Statement</b-button>

                  <b-button variant="soft-light" type="button"
                    @click="$router.push({ name: 'pm-property-list' })">Cancel</b-button>
                  <label class="form-label align-items-center pt-2"> Selected Rows</label> <label class="form-label align-items-center pt-2"> {{ selected_rows }} </label>
                </div>
              </div>
              <!-- end col -->




              <div class="col-md-7">
                <div class="mb-3 d-flex flex-wrap align-items-end justify-content-md-end gap-2">
                  <label class="form-label align-items-center pt-2"> Total Rows</label> <label
                    class="form-label align-items-center pt-2"> {{ rows }} </label>
                </div>
              </div>
            </div>



            <div class="table-responsive">
              <div class="table align-middle table-nowrap">
                <b-table :items="pmList" :fields="fields" :per-page="perPage" :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc" :filter-included-fields="['property_id']" thead-class="bg-light"
                  :head-variant="'light'" hover show-empty responsive="sm" style="min-height: 50dvh;">

                  <template #head(C)>
                    <input type="checkbox" class="form-check-input" id="checkAll" @change="onSelectAllPM($event)" />
                  </template>
                  <template #cell(C)="data">
                    <div class="form-check font-size-16">
                      <input type="checkbox" class="form-check-input" :value="data.item.property_id"
                        @change="onPMSelected($event, data.item)" :disabled="data.item.check_disable"
                        :checked="data.item.check_disable == true ? false : data.item.checked" />
                      <label class="form-check-label"></label>
                    </div>
                  </template>
                  <template #cell(PMID)="data">
                    <router-link :to="{ name: 'pm-overview', query: { pm_id: `${data.item.property_id}` } }"
                      class="text-body fw-medium" target="_blank">{{ data.item.property_id }}</router-link>
                  </template>
                  <template #cell(Address)="data">
                    {{ data.item.address }}
                  </template>

                  <template #cell(PropertyManager)="data">
                    {{ `${data.item.agent.first_name} ${data.item.agent.last_name}` }}
                  </template>


                  <template #cell(Deposit)="data">
                    ${{ f(data.item.deposit).toLocaleString() }}
                  </template>
                  <template #cell(Balance)="data">
                    ${{ f(data.item.balance_available).toLocaleString() }}
                  </template>
                  <template #cell(Email1)="data">
                    {{ data.item.email }}
                  </template>
                  <template #cell(Email2)="data">
                    {{ data.item.email2 }}
                  </template>
                  <template #cell(Email3)="data">
                    {{ data.item.email3 }}
                  </template>

                  <template #cell(Action)="data">
                    <b-dropdown variant="white" toggle-class="btn-link text-dark shadow-none">
                      <template v-slot:button-content>
                        <i class="uil uil-ellipsis-h"></i>
                      </template>

                      <b-dropdown-item @click="showPreview(data.item)">Preview</b-dropdown-item>
                    </b-dropdown>

                  </template>

                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <div class="row g-0 text-center text-sm-start">
              <!-- end col -->
              <div class="col-sm-12">
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-end">
                      <!-- pagination -->
                      <b-pagination class="pagination-rounded" v-model="currentPage" :total-rows="totalRows"
                        :per-page="perPage"></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
