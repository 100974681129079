<script>

import appConfig from "@/app.config";

import {
  required,
} from "vuelidate/lib/validators";
import {getPMApi} from '@/api/pm'

/**
 * Read-email component
 */
export default {
  page: {
    title: "Read Email",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  emits:['close'],

  props : {
    mail_item : {
      type : Object,
      default: ()=> { }
    },

    rta_list: {
      type : Array,
      default: ()=> []
    },
  },


  validations: {
    to : {required},
    title : {required}
  },
  
  components: {

  },

  data() {
    return {

      to            : '',
      title         : '',
      is_exporting  : false,
      exported      : false,
      download_url  : '',
      job_id        : '',
      task_id       : '',
      export_failed : '',
      progress      : 10,
      content       : ` \
            <p>Dear Owner,</p>     
                                   
            <p>Please find the ${ this.mail_item.start_date } - ${ this.mail_item.end_date } statement attached.
              If you have any questions pertaining to your statement, please contact your Property Manager directly.
            </p>
            <p></p>
            <p> Please do not reply to this email – this mailbox is not monitored.</p>
            <p> Warm regards,</p>
            <p> RE/MAX Masters Realty</p>
            <hr /> 
      `
    };
  },



  methods: {

    export_statements() {

      let property_list = []
      this.rta_list.map(e => property_list.push(e.property_id))
      let data = {
        property_list        : property_list,
        start_date           : this.mail_item.start_date,
        end_date             : this.mail_item.end_date,
        enable_description   : this.mail_item.description_flag =='1'?true : false
      }

      this.progress = 5
      this.is_exporting = true
      getPMApi().export_statement_list(data).then(res =>{
        if (res.errCode != 0) {
            this.is_exporting = false
            this.$alertify.error(" Export failed:" + res.errCode);
        } else {
            this.job_id = res.data.job.job_id
            this.task_id = setInterval(this.queryProgress, 2000)
        }
      })
    },


    send() {
      this.$v.$touch()
      if (this.$v.$invalid == false) {
        getPMApi().send_statement_mail(this.to, this.title, this.content, this.download_url).then(res => {
          if (res.errCode == 0) {
            this.$alertify.message("Send Mail Successfully " );
            this.$emit('close')
          } else {
            this.$alertify.error("Send Mail Failed " + res.errCode);
          }
        })
      }
    },


    queryProgress() {
      getPMApi().query_export_job_status({job_id : this.job_id}).then(res => {
          if (res.errCode == 0) {
              if (res.data.state =='FINISHED') {
                  clearInterval(this.task_id)
                  this.download_url = res.data.url
                  this.is_exporting = false
                  if (res.data.error != true) {
                    this.exported     = true
                    this.$alertify.message("Generate Statement Success ");
                  } else {
                    this.$alertify.error("Generate Statement Failed ");
                  }

                  this.progress = 100
              }
          } else {
              clearInterval(this.task_id)
              this.export_failed = true
              this.is_exporting = false
          }
      })

      this.progress += 3
    }
    
  },

  
  created() {
    

  },

  mounted() {
    this.exported = false
  }
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <!-- Left sidebar -->


      <!-- Right Sidebar -->
      <div class="mb-3">
        <div class="card">
          <div class="card-body">

            <div class="row mb-3">
              <input type="email" class="form-control" placeholder="To" v-model="to" :class="{'is-invalid': $v.to.$error}"/>
              <div v-if="$v.to.$error" class="invalid-feedback">
                <span v-if="!$v.to.required">This value is required.</span>
              </div>
            </div>

            <div class="row mb-3">
              <input  class="form-control" placeholder=" Statement Mail Preview" v-model="title"  :class="{'is-invalid': $v.title.$error}"/>
              <div v-if="$v.title.$error" class="invalid-feedback">
                <span v-if="!$v.title.required">This value is required.</span>
              </div>
            </div>

            <div class="row" v-if="is_exporting">
                <b-progress height="20px" :value="progress"></b-progress>
            </div>

            <!-- <div class="row mb-3" v-if="is_exporting">
                <b-alert show variant="danger">
                    Submitting Charge Data, please waiting.....
                </b-alert>
            </div> -->

            <span v-html="content"></span>
            <div class="row">
              <div class="col-xl-2 col-6">
                <div class="card">
              
                  <div class="py-2 text-center">
                    <a :href="download_url" target="_blank" class="fw-medium" >Attachment Download</a>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      <!-- card -->

      <div class="row">
        <div class="mb-3">
          <div class="col-auto d-flex flex-wrap justify-content-center gap-2">
            <b-button variant="secondary" class="primary" @click="$emit('close')">Close</b-button>
            <b-button variant="danger" class="primary" @click="export_statements" :disabled="is_exporting">Generate Statement</b-button>
            <b-button variant="primary" class="primary" @click="send" :disabled="!exported">Send</b-button>

          </div>
        </div>
      </div>
    </div>
    <!-- end Col-9 -->
  </div>
</template>
